import React from 'react'
import './About.css'

import Trello, { TRELLO_ABOUT_CARD_ID } from '../Trello.js'

const Format = text => {
    return text.replace(/\{calculate_year_from:(\d*)\}/, (match, year) => new Date().getFullYear() - year)
}

export default class About extends React.Component {
    constructor(){
        super()

        this.state = {
            imageURL: '',
            article: ''
        }

        this.image = React.createRef()
    }

    componentDidMount(){
        Trello(`card/${TRELLO_ABOUT_CARD_ID}`).then(data => {
            this.setState({
                imageURL: [data.cover.scaled[2].url, data.cover.scaled[4].url],
                article: Format(data.desc)
            }, () => {
                this.image.current.src = this.state.imageURL[0]
                this.image.current.onload = () => {
                    if(!this.image.current) return
                    if(this.state.imageURL[1]) this.image.current.src = this.state.imageURL[1]
                    this.image.current.onload = null
                }
            })
        }).catch(e => console.error(e))
    }

    render(){
        return (
            <div className="page" id="page_about">
                <h1>Pár slov o mne</h1>

                <div className="about_container">
                    <article id="about_article" dangerouslySetInnerHTML={{ __html: this.state.article }}></article>
                    <div className="about_image">
                        <img alt="" ref={this.image}></img>
                    </div>
                </div>
            </div>
          )
    }
}