import React from 'react'
import { Link } from 'react-router-dom'
import './Painting.css'

import Trello from '../Trello.js'

import {
    Splide,
    SplideSlide
} from '@splidejs/react-splide'
import '@splidejs/splide/dist/css/themes/splide-default.min.css'

export default class Painting extends React.Component {
    constructor(){
        super()

        this.state = {
            painting: null
        }
    }

    getPaintingData(painting){
        const desc_arr = painting.desc.split('\n')
        const getField = field => {
            const line = desc_arr.find(line => line.startsWith(`[${field}]`))
            return line == null ? '---' : line.split(`[${field}]`).join('')
        }

        return {
            full_name: getField('full name'),
            size: `<i class="fas fa-expand-alt"></i><div>${getField('size')}</div>`,
            technique: `<i class="fas fa-paint-brush"></i><div>${getField('technique')}</div>`,
            material: `<i class="fas fa-sticky-note"></i><div>${getField('material')}</div>`,
            year: `<i class="fas fa-calendar"></i><div>${getField('year')}</div>`,
            description: desc_arr.filter(line => !line.startsWith('[')).join('<br>')
        }
    }

    componentDidMount(){
        Trello(`card/${this.props.match.params.id}?attachments=true`).then(painting => {
            this.setState({
                painting
            })
        }).catch(e => console.error(e))
    }

    render(){
        const {painting} = this.state

        if(!painting) return null

        const backgroundImage = painting.cover?.scaled ? `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('${painting.cover.scaled[6].url}'), url('${painting.cover.scaled[2].url}')` : ''

        const paintingData = this.getPaintingData(painting)
        const fullName = paintingData.full_name

        let paintingInfoContainer = document.createElement('div')
        paintingInfoContainer.classList.add('painting_info_container')

        for(let info of [
            paintingData.size,
            paintingData.technique,
            paintingData.material,
            paintingData.year
        ]){
            let paintingInfoItem = document.createElement('div')
            paintingInfoItem.classList.add('painting_info_item')

            paintingInfoItem.innerHTML = info

            paintingInfoContainer.appendChild(paintingInfoItem)
        }

        const description = paintingData.description

        return (
            <div>
                <div className="page home" id="page_home">
                    <div
                        className="title"
                        style={{
                            position: 'relative',
                            backgroundImage: backgroundImage
                        }}
                    >
                        <h1 className="paintingName fancy">{fullName}</h1>
                        <div className="painting_info_container">
                            {[
                                paintingData.size,
                                paintingData.technique,
                                paintingData.material,
                                paintingData.year
                            ].map((info, index) => (
                                <div className="painting_info_item" dangerouslySetInnerHTML={{__html: info}} key={index}></div>
                            ))}
                        </div>
                    </div>
                </div>

                <div className="page visible" id="page_gallery">
                    <div id="gallery_container" className="single">
                        <article className="painting_content">
                            <div dangerouslySetInnerHTML={{__html: description}}></div>

                            {painting.labels.some(label => label.name === 'For sale') ? (
                                <p style={{textAlign: 'center'}}>
                                    Ak máte záujem o tento obraz, napíšte mi správu :)<br/>
                                    Kontaktné údaje nájdete v sekcii <Link to="/contact" style={{color: 'var(--main-color)'}}>Kontakt</Link>.
                                </p>
                            ) : null}
                        </article>
                    </div>

                    <Splide
                        options={{
                            type   : 'loop',
                            perPage: 1,
                            perMove: 1,
                            gap    : '1rem',
                            width  : '100%',
                        }}
                    >
                        {
                            painting.attachments.filter(attachment => attachment.previews).map((attachment, index) => (
                                <SplideSlide key={index}>
                                    <div style={{
                                        backgroundImage: `url('${attachment.previews[6].url}'), url('${attachment.previews[2].url}')`,
                                        display: 'block',
                                        width: '100%',
                                        height: '80vh',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundPosition: 'center',
                                        backgroundSize: 'contain'
                                    }}></div>
                                </SplideSlide>
                            ))
                        }
                    </Splide>
                </div>
            </div>
        )
    }
}