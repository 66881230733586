
const TRELLO_API_URL = 'https://api.trello.com/1/'

const Trello = url => new Promise((resolve, reject) => {
    fetch(`${TRELLO_API_URL}${url}`)
        .then(response => response.json())
        .then(data => resolve(data))
        .catch(error => reject)
})

const TRELLO_BOARD_ID = 'RX6Knlgc'
const TRELLO_HOME_CARD_ID = '5f625d011b077476d00700bd'
const TRELLO_ABOUT_CARD_ID = '5f5549eec329542a10d1a4d3'
const TRELLO_ORDER_CARD_ID = '5f58a9e4f200a2106e8944b0'
const TRELLO_PAINTINGS_LIST_ID = '5f54e4b916a8008f3f60f783'

export default Trello

export {
    TRELLO_BOARD_ID,
    TRELLO_HOME_CARD_ID,
    TRELLO_ABOUT_CARD_ID,
    TRELLO_ORDER_CARD_ID,
    TRELLO_PAINTINGS_LIST_ID
}