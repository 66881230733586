import React from 'react'
import './Contact.css'

export default class Contact extends React.Component {
    render(){
        const fs30px = {fontSize: '30px'}

        return (
            <div className="page" id="page_contact">
                <div className="social page_contact">
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start'
                    }}>
                        <a href="https://www.facebook.com/ivonasart" rel="noopener noreferrer" target="_blank" className="black">
                            <i className="fab fa-facebook-square icon"></i>
                            <div>fb.com/<strong>ivonasart</strong></div>
                        </a>
                        <a href="https://m.me/ivonasart" rel="noopener noreferrer" target="_blank" className="black">
                            <i className="fab fa-facebook-messenger icon" style={fs30px}></i>
                            <div>m.me/<strong>ivonasart</strong></div>
                        </a>
                        <a href="https://www.instagram.com/ivonasart" rel="noopener noreferrer" target="_blank" className="black">
                            <i className="fab fa-instagram icon"></i>
                            <div>@<strong>ivonasart</strong></div>
                        </a>
                        <a href="mailto:ivonasart@gmail.com" rel="noopener noreferrer" className="black">
                            <i className="fas fa-envelope icon" style={fs30px}></i>
                            <div><strong>ivonasart</strong>@gmail.com</div>
                        </a>
                    </div>
                </div>
            </div>
          )
    }
}