import React from 'react'
import './Order.css'

import Trello, { TRELLO_ORDER_CARD_ID } from '../Trello.js'

export default class Order extends React.Component {
    constructor(){
        super()

        this.state = {
            article: ''
        }
    }

    componentDidMount(){
        Trello(`card/${TRELLO_ORDER_CARD_ID}`).then(data => {
            this.setState({
                article: data.desc
            })
        }).catch(e => console.error(e))
    }

    render(){
        return (
            <div className="page" id="page_order">
                <article id="order_container" dangerouslySetInnerHTML={{ __html: this.state.article }}></article>
            </div>
          )
    }
}