import React from 'react'
import './App.css'

import {
	BrowserRouter as Router,
	Switch,
	Route,
	withRouter 
} from 'react-router-dom'

import Navigation from './Pages/Navigation.js'

import Home from './Pages/Home.js'
import About from './Pages/About.js'
import Contact from './Pages/Contact.js'
import Order from './Pages/Order.js'

import Gallery from './Pages/Gallery.js'
import Painting from './Pages/Painting.js'

class RawScrollToTop extends React.Component {
	componentDidUpdate(prevProps) {
		if(this.props.location.pathname !== prevProps.location.pathname){
			window.scrollTo(0, 0)
		}
	}

	render(){
	  	return null
	}
}

const ScrollToTop = withRouter(RawScrollToTop);

class App extends React.Component {
	render(){
		return (
			<div className="App">
				<Router>
					<ScrollToTop/>

					<div className="content">
						<Navigation/>

						<Switch>
							<Route exact path="/about">
								<About/>
							</Route>
							<Route path="/gallery/:id" component={Painting}/>
							<Route exact path="/gallery">
								<Gallery key={0} description="Moje najlepšie výtvory :)" filter={['Gallery']}/>
							</Route>
							<Route exact path="/shop/originals">
								<Gallery key={1} description="Tu nájdete všetky originálne obrazy, ktoré sú momentálne na predaj." filter={['For sale', 'Original']}/>
							</Route>
							<Route exact path="/shop/copies">
								<Gallery key={2} description="Tu nájdete kópie mojich obrazov." filter={['For sale', 'Copy']}/>
							</Route>
							<Route exact path="/order">
								<Order/>
							</Route>
							<Route exact path="/contact">
								<Contact/>
							</Route>
							<Route exact path="/">
								<Home/>
							</Route>
						</Switch>

						<div className="footer">
							<div className="social">
								<a rel="noopener noreferrer" href="https://www.facebook.com/ivonasart" target="_blank">
									<i className="fab fa-facebook-square icon"></i>
								</a>
								<a rel="noopener noreferrer" href="https://www.instagram.com/ivonasart" target="_blank">
									<i className="fab fa-instagram icon"></i>
								</a>
							</div>
							<div className="copyright">
								Developed and designed by <a href="mailto:matus.semancik@gmail.com">Matúš Semančík</a><br/>
								ⓒ 2020 - ∞ Ivona's Art
							</div>
						</div>

						{new Array(100).fill(0).map((_, i) => (
							<img
								alt=""
								key={i}
								src={`/splashes/${Math.floor(Math.random() * 5)}.svg`}
								style={{
									position: 'absolute',
									zIndex: -1,
									width: '100%',
									opacity: '.1',

									top: `calc(${i * 1000 / 2}px - 30%)`,
									left: `${Math.floor(Math.random() * 160) - 100}%`,

									filter: `invert(.5) sepia(1) saturate(5) hue-rotate(${Math.floor(Math.random() * 360)}deg)`
								}}
							/>
						))}
					</div>
				</Router>
			</div>
		)
	}
}

export default App