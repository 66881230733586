import React from 'react'
import './Home.css'

import Trello, { TRELLO_HOME_CARD_ID } from '../Trello.js'

export default class Home extends React.Component {
    constructor(){
        super()

        this.state = {
            imageURL: ''
        }

        this.image = React.createRef()
    }

    componentDidMount(){
        Trello(`card/${TRELLO_HOME_CARD_ID}`).then(data => {
            this.setState({
                imageURL: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('${data.cover.scaled[6].url}'), url('${data.cover.scaled[4].url}'), url('${data.cover.scaled[2].url}')`
            })
        }).catch(e => console.error(e))
    }

    render(){
        return (
            <div className="page home" id="page_home">
                <div className="title" style={{backgroundImage: this.state.imageURL}}>
                    <div className="mainHeader logo"></div>
                </div>
            </div>
          )
    }
}