import React from 'react'

import {
    withRouter,
	Link
} from 'react-router-dom'

import './Navigation.css'
import {
    Spin as Hamburger
} from 'hamburger-react'

class Navigation extends React.Component {
    constructor(){
        super()

        this.state = {
            opened: false
        }
    }

    handleMenuButtonClick = () => {
        this.setState({
            opened: !this.state.opened
        })
    }

    handleLinkClick = () => {
        this.setState({
            opened: false
        })
    }

    render(){
        return (
            <nav className={(this.props.location.pathname === '/' ? '' : 'staticFloat') + (this.state.opened ? ' opened' : '')}>
                <div className="logo"></div>

                <ul className="ulnav">
                    <li><Link to="/" onClick={this.handleLinkClick}>Domov</Link></li>
                    <li><Link to="/about" onClick={this.handleLinkClick}>O mne</Link></li>
                    <li><Link to="/gallery" onClick={this.handleLinkClick}>Galéria</Link></li>
                    <li>
                        <div className="a">Obchod</div>
                        <ul className="subnav">
                            <li><Link to="/shop/originals" onClick={this.handleLinkClick}>Originály</Link></li>
                            <li><Link to="/shop/copies" onClick={this.handleLinkClick}>Kópie</Link></li>
                        </ul>
                    </li>
                    <li><Link to="/order" onClick={this.handleLinkClick}>Zákazky</Link></li>
                    <li><Link to="/contact" onClick={this.handleLinkClick}>Kontakt</Link></li>
                </ul>

                <div className="mobile_menu_overlay" onClick={this.handleMenuButtonClick}></div>

                <div className="nav_button" onClick={this.handleMenuButtonClick} style={{
                    paddingTop: '3.5px'
                }}>
                    {/* <i className="fas fa-bars"></i> */}
                    <Hamburger toggled={this.state.opened}/>
                </div>
            </nav>
        )
    }
}

const NavigationWithRouter = withRouter(props => <Navigation {...props}/>)

export default NavigationWithRouter