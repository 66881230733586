import React from 'react'
import './Gallery.css'

import Trello, { TRELLO_PAINTINGS_LIST_ID } from '../Trello.js'

import {
	Link
} from 'react-router-dom'

const sortByDate = (a, b) => a.id > b.id ? -1 : 1

class GalleryImage extends React.Component {
    render(){
        const painting = this.props.data

        const paintingImage = painting.cover?.scaled ? `url('${painting.cover.scaled[4].url}'), url('${painting.cover.scaled[2].url}')` : ''
        const paintingSize = painting.desc.split('\n').filter(line => line.startsWith('[size]'))[0].split('[size]')[1]
        const paintingLink = `/gallery/${painting.id}`

        return (
            <Link to={paintingLink} className="painting_card">
                <div className="painting_card_image" style={{
                    backgroundImage: paintingImage
                }}></div>
                <div className="painting_card_content">
                    <h2>{painting.name}</h2>
                    <div style={{
                        position: 'absolute',
                        bottom: '12px'
                    }}>
                        <i className="fas fa-expand-alt" aria-hidden="true"></i>{paintingSize}
                    </div>
                </div>
            </Link>
        )
    }
}

export default class Gallery extends React.Component {
    constructor(){
        super()

        this.state = {
            data: []
        }
    }

    componentDidMount(){
        const filter = this.props.filter || []

        Trello(`list/${TRELLO_PAINTINGS_LIST_ID}/cards?attachments=true`).then(data => {
            data = data.sort(sortByDate).filter(painting => {
                return painting.labels.filter(label => filter.includes(label.name)).length === filter.length
            })

            this.setState({
                data
            })
        }).catch(e => console.error(e))
    }

    render(){
        const data = this.state.data
        const description = this.props.description || ''

        return (
            <div className="page" id="page_gallery">
                <h3 style={{textAlign: 'center'}}>{description}</h3>
                <div id="gallery_container">
                    {data.map((image, index) => (<GalleryImage key={index} data={image}/>))}
                </div>
                {/* <pre>{JSON.stringify(data, null, 4)}</pre> */}
            </div>
          )
    }
}